import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
  },
  {
    path: '',
    component: CustomLayoutComponent,
    children: [
      {
        path: 'dashboards/analytics',
        redirectTo: '/'
      },
      {
        path: '',
        loadChildren: () => import('./pages/dashboards/dashboard-analytics/dashboard-analytics.module').then(m => m.DashboardAnalyticsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'apps',
        children: [
          {
            path: 'users',
            loadChildren: () => import('./pages/apps/users/users.module').then(m => m.UsersModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'clientes',
            loadChildren: () => import('./pages/apps/clientes/clientes.module').then(m => m.ClientesModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'repartidores',
            loadChildren: () => import('./pages/apps/repartidores/repartidores.module').then(m => m.RepartidoresModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'categorias',
            loadChildren: () => import('./pages/apps/categorias/categorias.module').then(m => m.CategoriasModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'restaurantes',
            loadChildren: () => import('./pages/apps/restaurantes/restaurantes.module').then(m => m.RestaurantesModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'notificaciones',
            loadChildren: () => import('./pages/apps/notificaciones/notificaciones.module').then(m => m.NotificacionesModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'cupones',
            loadChildren: () => import('./pages/apps/cupones/cupones.module').then(m => m.CuponesModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'campanias',
            loadChildren: () => import('./pages/apps/campanias/campanias.module').then(m => m.CampaniasModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'costos',
            loadChildren: () => import('./pages/apps/costos/costos.module').then(m => m.CostosModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'reportes',
            loadChildren: () => import('./pages/apps/reportes/reportes.module').then(m => m.ReportesModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'porcentaje',
            loadChildren: () => import('./pages/apps/porcentaje/porcentaje.module').then(m => m.PorcentajeModule),
            canActivate: [AuthGuard]
          },
        ]
      },
    ]
  },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
